<template>
  <component
    v-bind:is="wrapper_component_loaded"
    :isLoading="isLoading"
    :options="wrapper_component.options"
    @refreshData="refreshData"
  >
    <template v-slot:default="slotProps">
      <component
        v-bind:is="component_loaded"
        ref="elementRef"
        :options="component.options"
        v-on:elementLoaded="elementLoaded"
        :wrapperProps="slotProps"
      ></component>
    </template>
  </component>
</template>

<script>
const axios = require("axios");

const bruna_elements = {
  //BrunaHighcharts: () => import("@/views/brunacomponents/elements/highcharts/BrunaHighcharts.vue"),
  BrunaHighcharts: () => import("@/views/brunacomponents/elements/highcharts/BrunaHighcharts2.vue"),
  BrunaHighstocks: () => import("@/views/brunacomponents/elements/highcharts/BrunaHighstocks.vue"),
  BrunaEcharts: () => import("@/views/brunacomponents/elements/echarts/BrunaEcharts.vue"),
  BrunaApexCharts: () => import("@/views/brunacomponents/elements/apexcharts/BrunaApexCharts.vue"),


  BrunaStatisticCardHorizontal: () => import("@/views/brunacomponents/elements/cards/StatisticCardHorizontal.vue"),
  BrunaStatisticCardVertical: () => import("@/views/brunacomponents/elements/cards/StatisticCardVertical.vue"),


  BrunaCardStatisticsGroup: () => import("@/views/brunacomponents/elements/cards/CardStatisticsGroup.vue"),


  BrunaGoodTable: () => import("@/views/brunacomponents/elements/table/GoodTable.vue"),


}

export default {

  components: {},
  directives: {},

  created()
  {
    this.isLoading = true;
  },

  methods: {


    refreshData()
    {
      this.$emit('refreshData');

    },

    updateElement() { },

    getRef()
    {
      return this.$refs.elementRef.getRef()
    },

    elementLoaded(element)
    {
      this.isLoading = false;
      console.log(element)

      this.$emit('elementLoaded', element);

      //console.log(this.$refs.elementRef)
    },

    getDefaultWrapper()
    {
      return () =>
        import("@/views/brunacomponents/wrappers/DefaultWrapper.vue");
    },

    loadComponent()
    {
      this.componentLoader()
        .then(() =>
        {
          this.component_loaded = () => this.componentLoader();
        })
        .catch(() =>
        {
          console.log(
            `El wrapper_component.name '${this.component.name}' no esta definido!!`
          );
          throw 'El component.name no esta definido!';

          //this.component_loaded = this.getDefaulComponent();
        });
    },

    loadWrapperComponent()
    {
      this.wrapperComponentLoader()
        .then(() =>
        {
          this.wrapper_component_loaded = () => this.wrapperComponentLoader();
        })
        .catch(() =>
        {
          console.log(
            `El wrapper_component.name '${this.wrapper_component.name}' no esta definido!! Procedo a cargar el DefaultWrapper`
          );

          this.wrapper_component_loaded = this.getDefaultWrapper();
        });
    },
  },

  computed: {

    componentLoader()
    {
      if (!this.component || !this.component.name)
      {
        throw 'El component.name no esta definido!!';
      }

      return bruna_elements[this.component.name]
    },

    wrapperComponentLoader()
    {
      if (!this.wrapper_component || !this.wrapper_component.name)
      {
        console.log(
          "El wrapper_component.name no esta definido!! Procedo a cargar el DefaultWrapper"
        );
        return this.getDefaultWrapper();
      }

      return () =>
        import(
          `@/views/brunacomponents/wrappers/${this.wrapper_component.name}.vue`
        );
    },
  },

  mounted()
  {
    this.loadWrapperComponent();
    this.loadComponent()






    /*
    var channel = this.$pusher.subscribe("charts");

    channel.bind("App\\Events\\ElementUpdateEvent", (message) =>
    {
      console.log(message);

      if (message.element.id == this.id)
      {
        console.log("Tengo que actualizar el element: " + message.element.id);

        let newSeries = message.data.options.series;

        newSeries.forEach((newSerie) =>
        {
          let series = this.$refs.chart.chart.get(newSerie.id);

          series.setData(newSerie.data, true, true);
        });
      }
    });*/
  },

  beforeDestroy()
  {
    //console.log("beforeDestroy 2");
  },

  data()
  {
    return {

      elementRef: null,

      wrapper_component_loaded: null,
      component_loaded: null,

      cardTitle: "",
      cardSubTitle: "",
      cardTooltipTitle: "",
      cardTooltipContent: "",
      isLoading: false,
    };
  },

  props: {
    wrapper_component: {
      type: Object,
      default: () => ({
        name: "CardTooltipWrapper",
        options: {},
      }),
    },

    component: {
      type: Object,
      default: () => ({
        name: "BrunaHighcharts",
        options: {
          title: {
            text: ''
          },
          xAxis: {
            categories: [
              'Apples',
              'Oranges',
              'Pears',
              'Grapes',
              'Bananas'
            ]
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Total fruit consumption'
            }
          },
          tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
            shared: true
          },
          plotOptions: {
            column: {
              stacking: 'percent'
            }
          },
          series: [
            {
              id: 'uno',
              name: 'John',
              data: [
                10,
                10,
                10,
                10,
                10
              ],
              type: 'column'
            },
            {
              id: 'dos',
              name: 'Jane',
              data: [
                10,
                10,
                10,
                10,
                10
              ],
              type: 'column'
            },
            {
              id: 'tres',
              name: 'Joe',
              data: [
                10,
                10,
                10,
                10,
                10
              ],
              type: 'column'
            }
          ]
        },
      }),
    },
    id: {
      type: String,
    },
    options: {
      type: Object,
    },
  },
};
</script>
