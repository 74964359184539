<template>
    <div>
      <b-overlay :show="showOverlay" rounded="sm">
        

        <bruna-element-loader :dataUrl="compareAllInvocationsMetricsParallel">
        </bruna-element-loader>
        

        <template #overlay>
          <div class="text-center">
            <b-spinner label="Loading..." />
            <p id="cancel-label">Cargando Resultados...</p>
          </div>
        </template>
      </b-overlay>
    </div>
  </template> 
  
  <script>
  
  
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  
  import
  {
    BTabs,
    BTab,
    BOverlay,
    BSpinner
  } from "bootstrap-vue";
  
  import useApiServices from '@/services/useApiServices.js';
  
  
  export default {
    data()
    {

      return {
        showOverlay: false,
        compareAllInvocationsMetricsParallel: useApiServices.compareInvocations.compareAllInvocationsMetricsParallel
  
  
      };
    },
    created()
    {
  
      
  
  
    },
  
    components: {
      BTabs,
      BTab,
      BOverlay,
      BSpinner,
      BrunaElementLoader,
  
  
  
    },
  
  
  
  };
  </script>
  
  <style scoped>
  .btn-padding {
    margin-right: 48px;
  }
  
  .m-estadisticas {
    margin-left: 20px;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  .icon-plan {
    padding-top: 5%;
  }
  </style>
  