<template>
  <div>
    <bruna-element
      v-if="!isLoading && wrapper_component && component"
      :wrapper_component="wrapper_component"
      :component="component"
      ref="brunaElementRef"
      @refreshData="refreshData"
      @elementLoaded="
        (element) => {
          this.$emit('elementLoaded', element);
        }
      "
    >
    </bruna-element>

    <div v-else>
      <b-overlay :show="true" blur="0" opacity=".75" rounded="sm">
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <div style="min-height: 500px"></div>
          </b-card-body>
        </b-card>

        <template #overlay>
          <div class="text-center">
            <b-icon
              icon="hourglass-split"
              font-scale="2"
              animation="cylon"
            ></b-icon>
            <p id="cancel-label">{{ $t('Please wait...') }}</p>
          </div>
        </template>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import BrunaElement from "@/views/brunacomponents/BrunaElement.vue";

import axios from "@axios";

import
{
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BOverlay,
  BButton,
  BIcon,
} from "bootstrap-vue";

export default {
  components: {
    BrunaElement,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BOverlay,
    BButton,
    BIcon

  },
  data() {
    return {
      isLoading: true,

      firstLoaded: false,

      wrapper_component: null,
      component: null,
    };
  },
  created() {
    this.updateElement();
  },

  props: {
    dataUrl: {
      type: String,
      required: true,
    },

    loadingStrategy: {
      type: String,
      default: "ONLY_AT_FIRST",
      validator(value) {
        // The value must match one of these strings
        return ['ONLY_AT_FIRST', 'ALWAYS'].includes(value)
      }
    },

  },

  watch: {
    dataUrl: function (newVal, oldVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)

      if(this.firstLoaded){
        this.updateElement()
      }


    }
  },

  methods: {

    refreshData()
    {

      //this.updateElement()

    },


    updateElement()
    {
      /*
      if (!this.firstLoaded)
      {
        this.isLoading = true;
      }*/

      if(this.loadingStrategy == "ONLY_AT_FIRST"){
        if (!this.firstLoaded){
          this.isLoading = true;
        }
      }else if(this.loadingStrategy == "ALWAYS"){
        this.isLoading = true;
      }else{}


      this.getData()
        .then((response) =>
        {
          this.wrapper_component = response.data.wrapper_component;
          this.component = response.data.component;


          if(this.loadingStrategy == "ONLY_AT_FIRST"){
            if (!this.firstLoaded){
              this.isLoading = false;
              this.firstLoaded = true;
            }
          }else if(this.loadingStrategy == "ALWAYS"){
            if (!this.firstLoaded){
              this.firstLoaded = true;
            }
            this.isLoading = false;
          }else{}


        })

        this.isLoading = false;
      // });
    },

    getRef() {
      return this.$refs.brunaElementRef.getRef();
    },

    getData() {
      /*
      return this.$http.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })
*/
      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
